import * as React from "react";
import * as Bulma from 'react-bulma-components';
import { Helmet } from 'react-helmet';
import '../styles/main.scss';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>prostir.io</title>
        <meta name="description" content="prostir.io revisioning social networking"></meta>
      </Helmet>
      <Bulma.Hero size="fullheight" className="main-hero">
        <Bulma.Hero.Body>
          <Bulma.Container textAlign="center">
            prostir.io
          </Bulma.Container>
        </Bulma.Hero.Body>
        <Bulma.Hero.Footer>
          <Bulma.Container>
            <Bulma.Content textAlign="right" style={{ padding: "0.5rem 1rem" }}>
              <p>
                Made with &#10084; in Canada by <a href="https://uglyunicorn.ca">Ugly Unicorn</a>
                {" "}//{" "}
                Photo by <a href="https://unsplash.com/@efekurnaz?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Efe Kurnaz</a>
                {" "}on{" "}
                <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a>
              </p>
            </Bulma.Content>
          </Bulma.Container>
        </Bulma.Hero.Footer>
      </Bulma.Hero>
    </>
  );
}

export default IndexPage;
